<template>
    <div class="flex-container flex-col">
        <div  class="flex-container flex-col mb24">
            <AjaxSearchSelect
                label="Seleziona Publisher"
                :feedUrl="ApiEndPoints.USER_SCOPE"
                :params="{
                    discriminator:USER_SCOPE_PUBLISHER_DISCRIMINATOR,
                    concessionaireId:wizardData.concessionaire.id
                }"
                @select="publisherSelected"
                :key="'ajax-search-'+ keyCount"
                :selected="currentPublisher"
            >
            </AjaxSearchSelect>
            
            <p>Oppure</p>
            <label class="flex-container flex-col pb8">
                <div class="flex-container heading-and-error">
                    <h3 class="bebas nomargin">Crea nuovo Publisher</h3>
                </div>
                <div class="flex-container">
                    <input 
                        required 
                        v-model="newPublisherName" 
                        type="text" 
                        name="concessionaireName" 
                        placeholder="Inserisci il nome della concessionaria"
                        class="noborder nopadding pl8 w100 pr8"
                    />
                    <input 
                        :disabled="newPublisherName.length < 3" 
                        type="submit" 
                        value="crea" 
                        class="noborder nopadding bebas pl24 pr24 small-btn" 
                        @click.prevent="createNewPublisher"
                    >
                </div>
            </label>
        </div>
    </div>
</template>
<script>
import {computed, ref, inject } from 'vue'
import {useStore} from 'vuex'
import AjaxSearchSelect from '@/components/formelements/AjaxSearchSelect'
import {ApiEndPoints} from '@/api/Endpoints'
import { 
    USER_ROLE_CONCESSIONAIRE_ADMIN,
    USER_SCOPE_PUBLISHER_DISCRIMINATOR
} from '@/utils/RolesAndPolicies'
import { USER_ROLE_CONCESSIONAIRE_USER } from '../../../utils/RolesAndPolicies'

export default {
    emits:['publisher-select', 'userscope-created'],
    components:{
        AjaxSearchSelect
    },
    props:{
        wizardData:{
            type:Object
        }
    },
    setup(props, context ){

        
        const store = useStore()
        const currentPublisher = ref(props.wizardData.publisher)
        const keyCount= ref(0)
        const me = inject('me')
        if(currentPublisher.value){
            context.emit('publisher-select', currentPublisher)
        }

        const publishers = computed(()=>{

            
            const scopes = store.getters['userscope/scopes']
                            .filter(scope => scope.discriminator ===USER_SCOPE_PUBLISHER_DISCRIMINATOR)
            
            if(!props.wizardData.publisher){
                
                if(me.value.roles[0] !== USER_ROLE_CONCESSIONAIRE_USER 
                    && me.value.roles[0] !== USER_ROLE_CONCESSIONAIRE_ADMIN
                ){
                    return scopes.filter(scope => scope.concessionaire.id === props.wizardData.concessionaire.id)
                }else{
                    return scopes.filter(scope => scope.concessionaire.id === me.value.userScope.id)
                }
                
            } else {
                if(props.wizardData.publisher.concessionaire.id === props.wizardData.concessionaire.id){
                    return scopes.filter(scope => scope.id !== props.wizardData.publisher.id)
                }else{
                    return scopes.filter(scope => scope.concessionaire.id === props.wizardData.concessionaire.id)
                }
            }
            
        })

        const publisherIsInCorrectScope = (id)=>{
            return props.wizardData.concessionaire.id === id
        }
        
        const publisherSelected =(event)=>{
            
            //currentPublisher.value = publishers.value.find(c=>c.id === parseInt(event.target.value))
            //context.emit('publisher-select', currentPublisher)  
            context.emit('publisher-select', event) 
        } 

        const newPublisherName = ref('')
        const createNewPublisher = async () => {
            //await store.dispatch('user/storeNewUserData', props.wizardData)
            const id = me.value.roles[0] === USER_ROLE_CONCESSIONAIRE_ADMIN ? me.value.userScope.id : props.wizardData.concessionaire.id
            const response = await store.dispatch('userscope/createUserScope', {
                name:newPublisherName, 
                discriminator:USER_SCOPE_PUBLISHER_DISCRIMINATOR, 
                concessionaireId:id
            })
            currentPublisher.value = response
            context.emit('userscope-created', currentPublisher)
            keyCount.value++
        }
        
        return{
            keyCount,
            currentPublisher,
            publishers,
            publisherSelected,
            createNewPublisher,
            newPublisherName,
            publisherIsInCorrectScope,
            ApiEndPoints,
            USER_SCOPE_PUBLISHER_DISCRIMINATOR
        }
    }
}
</script>
