<template>

  <div class="layout-list-header" >
        <span class="layout-list-header-title bebas f20 pl16">Utenti</span>
        <a href="#" class="btn-new" @click.prevent="showCreateUser">+</a>
    </div>


     <transition name="downup">
        <create-user-buttons 
        v-if="createUserVisible"
        @close-panel="hideCreateUser"
        @create-user="createUser"
    ></create-user-buttons>
    </transition>
    <user-creator-wizard v-if="showPanel" :defaultRole="creatingUser.role" @close-panel="hideCreateUser"></user-creator-wizard>

       <UserRolesList2 
        :filterPath="'user/filter'" 
        class="infotronik_user_filterlist"
        />
    <ListHeader
        :defaultSort="defaultSort"
        @input="onInput"
        @change="onSort"
    ></ListHeader>
    

   <div class="layout-list-content infotronik_users_listautenti" >
        <ul class="layout-list nopadding nomargin">
            <UserListElement 
                v-for="user in users" :key= "user.id" 
                :user="user" 
                :selectedId="selectedId">
            </UserListElement>
        </ul>
    </div>
    <Pagination
       :pagination="pagination"
       @page-change="onPageChange"
    ></Pagination>
</template>
<script>
import {ref} from 'vue'
import {computed} from 'vue'
import {useStore} from 'vuex'
import UserListElement from '@/components/user/UserListElement'
import ListHeader from '@/components/ListHeader'
/*import UserRolesList from '@/components/user/UserRolesList'*/
import UserRolesList2 from '@/components/user/UserRolesList2'
import Pagination from '@/components/Pagination'
import UserCreatorWizard from '@/components/user/UserCreatorWizard'
import CreateUserButtons from '@/components/user/CreateUserButtons'
export default {
    props:{
        users:{
            type:Array,
            default:()=>[]
        },
        selectedId:{
            type:String,
            default:''
        },
        defaultSort: {
            type: String,
            default: 'Nome asc'
        }
    },
    async mounted() {
        const defaultSorting = { type: 'Nome asc' };
        this.onSort({ target: { value: defaultSorting } });
    },
    data() {
        return {
            selectedSort: this.defaultSort
            
        }
    },
    components:{
        UserListElement,
        ListHeader,
         CreateUserButtons,
     
        UserCreatorWizard,

      /* UserRolesList,*/
         UserRolesList2,
        Pagination
    },
    setup(){
        const store = useStore()
        
        const onInput = (filter)=>{
            store.dispatch('user/filter', filter) 
        }
        const onSort =(sorting)=>{        
            store.dispatch('user/sort', sorting)
        }
        const pagination = computed(()=>{
            return store.getters['user/pagination']
        })
        const onPageChange = async (page)=>{
           
            await store.dispatch('user/users', {PageNumber:page})
           
        }
 

         //const store = useStore()
        const createUserVisible = ref(false)
        const showPanel = ref(false)
        const creatingUser = ref(false)
        
        const showCreateUser = ()=>{
            createUserVisible.value = true
        }
        const hideCreateUser = ()=>{
            createUserVisible.value = false
            showPanel.value=false
        }
        const createUser = async(user)=>{
            showPanel.value=true
            creatingUser.value=user
        }


       return{
            onInput,          
            onSort,
            pagination,
            onPageChange,
            showCreateUser,
            hideCreateUser,
            creatingUser,
            createUserVisible,
            createUser,
            showPanel
        }
    


    }
}
</script>