<template>
    <form class="create flex-container flex-col">
            <div class="panel-header mb24">
                <div class="mini-logo"></div>
                <h2 
                    class="panel-title nomargin bebas"
                >
                    Stai creando un utente {{wizardData.role !== '' ? wizardData.role : defaultRole}}
                </h2>
            </div>
            <user-base-info 
                v-if="wizardData.step === 0" 
                :defaultRole="defaultRole"
                :wizardData ="wizardData" 
                @vuel-error="preventNext=true"
                @vuel-valid="onValidatedData"
            ></user-base-info>
            

            <div class="flex-container ">
                <input 
                    type="reset" 
                    value="annulla" 
                    class="w100 noborder nopadding bebas" 
                    @click.prevent="back"
                >
                <input 
                    :disabled="preventNext" 
                    type="submit" 
                    value="salva" 
                    class="w100 noborder nopadding bebas" 
                    @click.prevent="next"
                >
            </div>    
        </form>
</template>
<script>


import {reactive, ref, computed, inject} from 'vue'
import {useStore} from 'vuex'
import UserBaseInfo from '@/components/user/wizard/UserBaseInfo'


export default {
    emits: ['close-panel'],
    props:{
        defaultRole:{
            type:String
        }
    },
    components:{
        UserBaseInfo,
       
    },
    setup( props , {emit}){
       
        const store = useStore()
        const me = inject('me')

        const wizardData = reactive({
            step:0,
            userName:null,
            email:null,
            role:'',
            concessionaire:null,
            publisher:null
        })

        const onValidatedData =(data)=>{
            const {role, userName, email} = data
            preventNext.value = false
            wizardData.role = role
            wizardData.userName = userName
            wizardData.email = email
        }

        


        const back = ()=>{
            abortCreate()
        }

        const preventNext = ref(true)

        const next = () =>{
            save()
        } 

        const abortCreate = async() => {
            emit('close-panel')
        }
        const save = async () => {

            const data = {
                userName:wizardData.userName,
                email:wizardData.email,
                role:wizardData.role
            }
            
            data.userScopeId = me.value.userScope.id

            
            try{
                await store.dispatch('user/saveUser', {data:data})
                await store.dispatch('messages/message', { title:'Utente creato', message:data.userName })
            }catch(error){
                console.log(error)
                await store.dispatch('messages/message', { title:'Errore nella creazione utente', message:error.message })
            }
            
           // store.commit('user/user_edit', {editing:false})
        }

        const nextButtonText = computed(() =>{
            
            let text = 'next'
            
            if(wizardData.step === 1 ){
                text = 'salva'
            }
            if(wizardData.step === 2 ){
                text = 'salva'  
            }

            return text

         })

        return {
            wizardData,
            abortCreate,
            back,
            next,
            preventNext,
            onValidatedData,
            nextButtonText
        }

    }
}
</script>