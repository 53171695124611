<template>
    <label :class="className" :for="name">
        <div class="flex-container heading-and-error flex-start-align no-grow">
            <h3 class="bebas nomargin">{{label}}</h3>
        </div>
        <select
            v-if="editing"  
            :name="name" 
            :id="name" 
            class="noborder nopadding" 
            @change="onSelect" 
            v-model="selectedValue" 
        >
            <option :selected="!selectedValue" value="seleziona" disabled>----seleziona----</option>
            <option v-if="selectedValue" selected :value="selectedValue">{{selectedValue}}</option>
            <option v-for="(option, indx) in availableOptions"  :value="option" :key="indx">{{option}}</option>  
        </select>
        <select v-else class="noborder nopadding pl8" disabled>
            <option v-if="selectedValue" :value="selectedValue">{{selectedValue}}</option>
            <option v-else :value="placeholder" disabled selected>select</option>
        </select>
    </label>
</template>
<script>
import {computed, ref} from 'vue'
export default {
    emits:['select-change', 'change'],
    props:{
        name:{
            type: String,
            default:''
        },
        label:{
            type: String,
            default:''
        },
        placeholder:{
            type:String,
            default:'insert value here'
        },
        value:{
            type:String,
            default:''
        },
        editing:{
            type:Boolean,
            default:false
        },
        options:{
            type:Array,
            default:()=>[]
        },
        className:{
            type:String,
            default:'flex-container flex-col flex-0 mb8'
        }
    },
    components:{
    },
    setup(props,  context  ){
        
        const selectedValue = ref(props.value);
        const onSelect = (e)=>{
            context.emit('select-change', e.target.value) 
            context.emit('change', e) 
        }
        

        const availableOptions = computed(()=>props.options.filter(o=>o !== selectedValue.value))
        
       
        return{
            onSelect,
            selectedValue,
            availableOptions
        }

    }
}
</script>