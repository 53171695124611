<template>
    <form 
        class="card-form edit-user flex-container flex-col" 
        @submit.prevent="save" 
        ref="form"
        :key="user.id+'-'+ keyCounter"
    >
        <div class="layout-content-header pt24 pl24 pr24 infotronik_user_header">
            <h1 class="nomargin bebas">{{user.userName}}</h1>
        </div>

        <div class="content p24 flex-container infotronik_users_schermatautente">
            <div class="flex-container flex-col pl16"> 
                <div class="flex-container flex-col">
                   
                    <text-input
                        :label="'username'"
                        :value="user.userName"
                        :editing="editing"
                        :placeholder="'inserisci nome Utente'"
                        :name="'name'"
                    ></text-input>
                    <email-input 
                        placeholder="email" 
                        label="email" 
                        :email="user.email" 
                        :editing="editing"
                    ></email-input>
                    
                    <user-role-select 
                        :selected="user" 
                        :editing="editing" 
                        @change-role="onRoleSelect"
                        :keysuffix="keyCounter"
                    ></user-role-select>
                    
                    <AjaxSearchSelect
                        v-if="user.roles[0] !== 'Admin' && user.roles[0] !== 'Advertiser'"
                        :editing="editing && isAdmin()"
                        label="Publisher"
                        :feedUrl="ApiEndPoints.USER_SCOPE"
                        :params="{
                            discriminator:user?.userScope?.discriminator,
                        }"
                        @select="onScopeSelect"
                        :key="'ajax-search-publisher-'+ keyCounter"
                        :selected="user.userScope"
                        :className="'flex-container flex-col flex-0 mb8'"
                    >
                    </AjaxSearchSelect>
                    <text-input
                        v-if="concessionaire"
                        :label="'Concessionaire'"
                        :value="concessionaire.name"
                        :editing="false"
                    ></text-input>
                </div>
            </div>
            <FileUpload
                :editing="editing"
                :fileurl ="user?.avatar?.id ? API_BASE +'/file/'+ user.avatar.id : ''"
                @image-selected="onImageSelected"
                class="infotronik_user_imagecontainer"
            ></FileUpload>
        </div>
        <form-footer
            @edit-enable="editing = true"
            @edit-disable="disableEdit"
            @submit-form="save"
            @delete-press="onDeleteClick"
            :editing="editing"
        ></form-footer>
    </form>
     <ConfirmPanel
        v-if="confirmVisible"
        :title="'Elimina ' + user.userName"
        :message="'sei sicuro di voler eliminare ' + user.userName + '?'"
        @allow-confirm="onDeleteConfirm"
        @deny-confirm="confirmVisible = false"
    >
    </ConfirmPanel>
</template>
<script>
import {ref, computed, reactive} from 'vue'
import{useRouter} from 'vue-router'
import{useStore} from 'vuex'
import { API_BASE, ApiEndPoints } from '@/api/Endpoints'
import AjaxSearchSelect from '@/components/formelements/AjaxSearchSelect'
import {useCapabilities} from '@/utils/Capabilities'
import UserRoleSelect from '@/components/user/UserRoleSelect'
import EmailInput from '@/components/formelements/EmailInput'
import FormFooter from '@/components/formelements/FormFooter'
import TextInput from '@/components/formelements/TextInput'
import ConfirmPanel from '@/components/ConfirmPanel'
import FileUpload from '@/components/FileUpload'

export default {
    emits: ['remove-createduser'],
    components:{
        /* UserScopeSelect, */
        TextInput,
        UserRoleSelect,
        AjaxSearchSelect,
        EmailInput,
        FormFooter,
        ConfirmPanel,
        FileUpload
    },
    props:{
        selectedUser:{
            type:Object,
            default:()=>{}
        }
    },
    setup(props){
        const { isAdmin } = useCapabilities()
        
        const keyCounter = ref(0)
        let user = reactive(props.selectedUser)
        
        const store = useStore() 
        const router = useRouter() 
        const editing = ref(false) 
        const concessionaire = user?.userScope?.concessionaire ? ref(user.userScope.concessionaire) : ref(null)
        const avatar = ref(null)
        
        const availableScopes = computed(()=>{
            return user.userScope 
                ?   store.getters['userscope/scopes']
                        .filter(s => s.discriminator === user.userScope.discriminator) 
                : null
        })
        
        const onScopeSelect = (scope)=>{
            console.log('scope select', scope)
            //user.userScope = store.getters['userscope/scopes'].find(s => s.id === parseInt(scopeId))
            user.userScope = scope
            
            
            concessionaire.value = user.userScope.concessionaire
        }
        const onRoleSelect = ({role, discriminator})=>{
            user.roles[0] = role
            if(role === 'Admin' || role === 'Advertiser'){
                user.userScope = null
            } else {
                if(!user.userScope || user.userScope.discriminator !== discriminator ){
                    user.userScope = {}
                    user.userScope.discriminator = discriminator
                }   
            }
        }

    
        const form =ref(null)
        const enableEdit = ()=> {editing.value = false}

        const disableEdit = async ()=> { 

            const singleUser = await store.dispatch('user/getSingle', props.selectedUser.id)
            Object.keys(user).forEach(key =>{
                user[key] = singleUser[key]
            })


            editing.value = false
            keyCounter.value++
        }

        const onImageSelected =(files)=>{
            avatar.value = files[0]
        }
        const uploadAvatar = async() => {
            await store.dispatch('user/upload', {
                avatar:avatar.value, id:user.id
            })
        } 
        
        const save = async () => {
            let hasAvatar = true
            if(avatar.value){
                hasAvatar = false
                try {
                    await uploadAvatar()
                    hasAvatar = true
                } catch (error) {
                    await store.dispatch('messages/message', {
                        title:"errore nell'upload dell'avatar",
                        message:error.message
                    })
                }
                
            }
            if(hasAvatar){
                const formData = new FormData(form.value)
                const data = {}
                for (let key of formData.keys()) {
                    data[key] = formData.get(key)
                }
                if(props.selectedUser.id){
                    try{
                        await store.dispatch('user/update', {
                            id:props.selectedUser.id,
                            data:{...data, userScopeId: user?.userScope?.id || null}
                        })
                        await store.dispatch('messages/message', {
                            title: props.selectedUser.userName,
                            message:'Utente modificato correttamente' 
                        })
                    }catch(error){
                        await store.dispatch('messages/message', {
                            title:'errore nella modifica utente',
                            message:error.message
                        })
                    }
                
                } 
                //store.commit('user/user_edit', {editing:false})
            }
            
        }
        const confirmVisible = ref(false)
        
        const onDeleteClick = async()=>{
            
            confirmVisible.value=true
        }
        const onDeleteConfirm  = async()=>{
            
            try {
                await store.dispatch('user/remove', props.selectedUser.id)
                await store.dispatch('messages/message', {
                    title:'utente eliminato',
                    message:'hai cancellato ' + props.selectedUser.userName
                })
                
                router.push('/users')
                window.location = "/users";

            } catch (error) {
                await store.dispatch('messages/message', {
                    title:'errore nella eliminazione utente',
                    message:error.message
                })
            }
        }

        
       
        return {
            enableEdit,
            disableEdit,
            onRoleSelect,
            user,
            save,
            form,
            availableScopes,
            editing,
            onScopeSelect,
            confirmVisible,
            onDeleteClick,
            onDeleteConfirm,
            concessionaire,
            API_BASE,
            ApiEndPoints,
            onImageSelected,
            keyCounter,
            isAdmin
        }
    }
}
</script>
<style>
input[type="button"]{
  background-color: #F2F2F2;
  font-size: 14px;
  line-height: 32px;
  height: 32px;
  font-family:inherit;
  position: relative;
}
input[type="button"]:focus{
  outline: none;
  height: 31px;
  border-bottom: 1px solid #2cbeff;
}


label{
    font-size: 1.17em;
}

</style>